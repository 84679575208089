import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from "clsx";
// import { WrapperContext } from "./Layout";
import Menu from "./Menu";
import Burger from "./ui/Burger";
import SunriseSunsetUI from "./SunriseSunsetUI";
import SunriseSunsetFavicon from "./SunriseSunsetFavicon";
// import useSunriseSunset from "../contexts/SunriseSunsetContext";
// import LinkFade from "./ui/TransitionLinks/LinkFade";
// import { Link } from "gatsby";
import Logo from "./Logo";

const query = graphql`
  query {
    sanityHeader {
      siteName
      nav: _rawNav(resolveReferences: { maxDepth: 2 })
    }
  }
`;

const Header = () => {
  const {
    sanityHeader: { siteName, nav },
  } = useStaticQuery(query);

  // const { getColor } = useSunriseSunset();
  // const { background, foreground } = getColor();

  return (
    <header
      className={clsx(
        "p-sm-ajusted md:p-md-ajusted text-_lg md:text-lg z-10 fixed w-full top-0"
      )}>
      <Burger />
      <div className='row '>
        <div className='col-md-4 col-xs'>
          <Link to='/' className='site-name' activeStyle={{}}>
            {/* {siteName} */}
            <Logo />
          </Link>
        </div>
        <div className='col-md-4'>
          <Menu input={nav} />
        </div>
        <div className='col-md-4 col-xs'>
          <SunriseSunsetUI />
        </div>
      </div>
      <SunriseSunsetFavicon letter='F' />
    </header>
  );
};

export default Header;
