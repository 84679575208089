import React, { useEffect } from "react";
import useSunriseSunset from "../contexts/SunriseSunsetContext";

const Favicon = ({ letter }) => {
  const { getColor } = useSunriseSunset();

  useEffect(() => {
    const { background, foreground } = getColor();
    draw(background, foreground);
  }, [getColor]);

  const draw = (background, foreground) => {
    // const { texte, background, foreground } = this.props;
    const favicon = document.querySelector("link[rel='icon']");
    // console.log(favicon);

    const faviconSize = 64;
    const faviconSizeHalf = faviconSize / 2;

    const fontStyle = "40px SuisseIntl-Mono";
    // console.log(fontStyle);
    const canvas = document.createElement("canvas");
    canvas.width = faviconSize;
    canvas.height = faviconSize;

    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.src = favicon.href;

    img.onload = () => {
      // Draw Original Favicon as Background
      context.drawImage(img, 0, 0, faviconSize, faviconSize);

      context.beginPath();
      context.fillStyle = background;
      //context.strokeStyle = "black";
      context.font = fontStyle;
      context.textAlign = "center";
      context.textBaseline = "top";

      //const textHeight = this.getTextHeight(context.font, fontStyle)
      // context.fillRect(0, 0, faviconSize, faviconSize);
      context.arc(
        faviconSizeHalf,
        faviconSizeHalf,
        faviconSizeHalf,
        0,
        2 * Math.PI,
        false
      );
      // context.fillStyle = "green";

      context.fill();
      context.beginPath();
      context.fillStyle = foreground;
      context.fillText(letter, faviconSizeHalf, faviconSizeHalf / 2 - 1);
      context.fill();

      // Replace favicon
      favicon.href = canvas.toDataURL("image/jpg");
    };
  };

  return null;
};

export default Favicon;
