import clsx from "clsx";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import useSunriseSunset from "../contexts/SunriseSunsetContext";

//https://codepen.io/ahmedghazi/pen/GRvyjWe?editors=1100
const Wrapper = styled.div`
  // right: 40vw;
  @media all and (min-width: 1080px) {
    transform: translateY(-0.2em);
  }
  @media all and (max-width: 1080px) {
    right: 80px;
  }
`;

const radius = "33px";
const Sphere = styled.div`
  width: ${radius};
  height: ${radius};
  border-radius: 100%;
  /* box-shadow: 0px 0px 5px var(--radial-60); */

  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  .moment {
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0; */

    transition: opacity 2s ease;
    /* &.is-active {
      opacity: 1;
    } */
    width: ${radius};
    height: ${radius};
    border-radius: 100%;
    box-shadow: 0px 0px 10px var(--radial-60) inset;
    background-image: radial-gradient(
      circle at 70% 30%,
      var(--radial-0) 0%,
      var(--radial-20) 20%,
      var(--radial-60) 80%,
      var(--radial-0) 99%
    );
    &.moment-4::before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 50%;
      height: 50%;
      bottom: 10%;
      left: 10%;
      background: var(--radial-0);
      border-radius: 100%;
      filter: blur(7px);
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 30%;
      height: 30%;
      bottom: 10%;
      right: 10%;
      background: var(--radial-shade);
      border-radius: 100%;
      filter: blur(7px);
    }
  }

  /* .inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: background-color 2s ease;


    box-shadow: 0px 0px 5% var(--radial-60) inset;
    background: var(--radial-0);

    background-image: -moz-radial-gradient(
      circle at 70% 30%,
      var(--radial-0) 0%,
      var(--radial-20) 20%,
      var(--radial-60) 80%,
      var(--radial-0) 98%
    );

    &:after {
      content: "";
      position: absolute;
      border-radius: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0px 0px 10px var(--radial-60) inset;
    }

  } */
`;

const SunriseSunsetUI = () => {
  const { index, moments, next } = useSunriseSunset();
  const [isSeeking, setIsSeeking] = useState(false);

  // useEffect(() => {}, [isSeeking]);

  const _update = () => {
    setIsSeeking(true);
    next();

    setTimeout(() => {
      setIsSeeking(false);
    }, 2000);
  };
  // console.log(index);
  return (
    <Wrapper
      className={clsx(
        "sunrise-sunset-ui fixed z-20 md:right-lg md:top-md text-center cursor-pointer",
        isSeeking ? "pointer-events-none" : "pointer-events-auto"
      )}
      onClick={() => _update()}>
      <div className='inner pointer-events-none'>
        <Sphere className='shape flex'>
          {/* <div className='inner'></div> */}
          {moments.map((el, i) => (
            <div
              className={clsx(
                "moment flex-shrink-0",
                `moment-${i}`,
                index === i ? "is-active opacity-100" : "opacity-0"
              )}
              key={i}
              style={{
                "--radial-0": `var(--radial-${i}-0)`,
                "--radial-20": `var(--radial-${i}-20)`,
                "--radial-60": `var(--radial-${i}-60)`,
                "--radial-shade": `var(--radial-${i}-shade)`,
              }}></div>
          ))}
        </Sphere>
        <div className='absolute left-1/2 -translate-x-1/2 py-xs whitespace-nowrap text-_sm md:text-sm'>
          {moments[index]}
        </div>
      </div>
    </Wrapper>
  );
};

export default SunriseSunsetUI;
