/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react";
import Helmet from "react-helmet";
import PubSub from "pubsub-js";
import { Layout } from "./src/components/Layout";
// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script src='https://unpkg.com/pace-js@1.0.2/pace.min.js'></script>
        <script src='https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js'></script>
      </Helmet>
      {element}
    </>
  );
};

const _wrapPageElement = ({ element, props }) => {
  // console.log(Layout)
  return <Layout {...props}>{element}</Layout>;
};

const _onClientEntry = () => {
  // console.log("We've started!");
  // callAnalyticsAPI()
  const isTouch = "ontouchstart" in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add("touch");
  } else {
    document.documentElement.classList.add("no-touch");
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) document.documentElement.classList.add("is-safari");
  // window.addEventListener("DOMContentLoaded", (event) => {
  //   document.documentElement.classList.add("is-ready");
  // });
};

const _onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("new pathname", location.pathname, prevLocation);
  PubSub.publish("ROUTE_UPDATE");
};

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate };
