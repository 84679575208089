import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import ForestLogo from "../images/forest-logo-n.inline.svg";

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//    opacity: 1;
//   }
// `;

const dashIn = keyframes`
  to {
    stroke-dashoffset: 0;
    opacity:1
  }
`;
const dashOut = keyframes`
  to {
    stroke-dashoffset: 290;
    opacity:1;
  }
`;

const Container = styled.div`
  width: 100px;
  .site-name {
    transform: translate(calc(-50% + 0.3em), -50%);
  }
  .logo-layer {
    transition: opacity 400ms ease-out;
    pointer-events: none;
    opacity: 0;
  }

  .periph {
    opacity: 1;
    stroke-dasharray: 290;
    stroke-dashoffset: 290;
    animation: ${dashIn} 800ms ease 100ms 1 normal forwards;
  }

  &:hover {
    .logo-layer:not(.periph) {
      opacity: 1;
    }
    .periph {
      stroke-dashoffset: 0;

      animation: ${dashOut} 600ms ease 0ms 1 normal forwards;
    }
  }
`;

const Logo = () => {
  const ref = useRef();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    let delay = 70;
    let layers = ref.current.querySelectorAll(".logo-layer:not(.periph)");
    layers = [...layers];
    // layers.reverse();
    // console.log(layers);
    if (!hover) {
      layers.reverse();
      // delay = 100;
    }
    setTimeout(() => {
      // console.log(layers.length * delay);
      layers.forEach((el, i) => {
        el.style.transitionDelay = `${i * delay}ms`;
      });
    }, layers.length * delay);
  }, [hover]);

  return (
    <Container
      className='forest-logo z-10'
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <ForestLogo />
      <div className='site-name absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-md'>
        FOREST
      </div>
    </Container>
  );
};

export default Logo;
