import React from "react";
import ReactCookieConsent from "react-cookie-consent";
// import { _localizeField, _localizeText } from "../../core/utils";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SanityTexte from "../SanityTexte";
// import { _getLocalizedText } from '../../lib/helpers'
import { publish } from "pubsub-js";

const CookieConsent = ({ message }) => {
  const { settings } = useSiteMetadata();
  // console.log(settings);
  return (
    <ReactCookieConsent
      // debug={true}
      containerClasses='cookie-consent '
      contentClasses='cookie-consent--content'
      disableStyles={true}
      location='bottom'
      buttonText="J'ACCEPTE"
      buttonClasses='btn btn--big block'
      declineButtonText='DÉCLINER'
      cookieName='gatsby-gdpr-google-analytics'
      acceptOnScroll={false}
      onAccept={(acceptedByScrolling) => publish("COOKIE_CONSENT_ACCEPT")}>
      <div className='mb-1'>
        <p>{settings.messageCookies}</p>
      </div>
    </ReactCookieConsent>
  );
};

export default CookieConsent;
