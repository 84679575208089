import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { _linkResolver } from "../core/utils";
import SanityTexte from "./SanityTexte";
// import Mailchimp from "./ui/Mailchimp";

const query = graphql`
  query {
    sanityFooter {
      links: _rawLinks(resolveReferences: { maxDepth: 10 })
      about: _rawAbout(resolveReferences: { maxDepth: 10 })
      newsletter {
        label
        link
      }
    }
  }
`;

const Footer = () => {
  const {
    sanityFooter: { links, about, newsletter },
  } = useStaticQuery(query);

  return (
    <footer className='py-md pb-lg md:py-md text-sm'>
      <div className='mini-about text-lg py-lg mb-md px-sm md:px-md'>
        <SanityTexte input={about} />
      </div>
      <div className='px-sm md:px-md flex flex-col md:flex-row items-baseline justify-between'>
        <div className='left flex items-baseline'>
          <div className='copyright pr-md'>
            ©{new Date().getFullYear()} FOREST ALL RIGHT RESERVED
          </div>
          <ul className='md:flex flex-wrap pr-sm items-baseline'>
            {links.map((li, i) => (
              <li key={i} className='pr-md mb-xs md:mb-0'>
                {li._type === "externalLink" ? (
                  <a href={li.link} target='_blank' rel='noopener, noreferrer'>
                    {li.label}
                  </a>
                ) : (
                  <Link to={_linkResolver(li.link)}>{li.label}</Link>
                )}
              </li>
            ))}
            <div className='sm-only flex-2 mb-1e'>
              <a
                href={newsletter.link}
                target='_blank'
                rel='noopener, noreferrer'
                className='uppercase '>
                {newsletter.label}
              </a>
            </div>

            <li className='credits uppercase'>
              <p>
                Design{" "}
                <a
                  href='https://laandstudio.com'
                  target='_blank'
                  rel='noopener, noreferrer'>
                  Laand Studio
                </a>
                {", "}
                Code{" "}
                <a
                  href='https://ahmedghazi.com/'
                  target='_blank'
                  rel='noopener, noreferrer'>
                  Ahmed Ghazi
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className='right hidden-sm'>
          <a
            href={newsletter.link}
            target='_blank'
            rel='noopener, noreferrer'
            className='uppercase '>
            {newsletter.label}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
