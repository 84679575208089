import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site, sanitySettings } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteUrl
            defaultBanner: banner
            ogLanguage
            author
            twitter
            facebook
          }
        }

        sanitySettings {
          siteName
          introKeywords
          message404
          messageCookies
          credits: _rawCredits(resolveReferences: { maxDepth: 10 })
          contact
        }
      }
    `
  );
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: sanitySettings,
  };
};
