import React from "react";
import PortableText from "@sanity/block-content-to-react";
import MosaicImageTexte from "./MosaicImageTexte";
// import SanityImage from "./SanityImage";
// import VideoEmbed from "./ui/VideoEmbed";

const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props);
        switch (props.node.style) {
          case "h2":
            return <h2>{props.children}</h2>;
          case "text_lg":
            return <p className='text-lg'>{props.children}</p>;
          default:
            return <p>{props.children}</p>;
        }
      },
      // image: ({ node }) => {
      //   return <SanityImage input={node} />;
      // },
      mosaicImageTexte: ({ node }) => {
        return <MosaicImageTexte input={node} />;
      },
    },

    marks: {
      align_center: ({ children }) => (
        <span className='text-center'>{children}</span>
      ),
      sup: ({ children }) => <sup>{children}</sup>,
      internalLink: ({ mark, children }) => {
        return "aa";
      },
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        // console.log(mark, children);
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target='_blank' rel='noopener, noreferrer'>
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };
  // console.log(input)
  return (
    <div className='texte'>
      <PortableText blocks={input} serializers={serializers} />
    </div>
  );
};

export default SanityTexte;
