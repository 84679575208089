import React from "react";
import styled from "styled-components";
import SanityImage from "./SanityImage";

const Container = styled.div`
  img {
    aspect-ratio: 161 / 222;
  }
`;

const MosaicImageTexte = ({ input }) => {
  const column = Math.floor(12 / input.column);
  // console.log(input);
  return (
    <Container className='mosaic-image-texte mb-md'>
      <h2 className='mb-1e'>{input.title}</h2>
      <div className='row'>
        {input.items.map((item, i) => (
          <div
            className={`col-xs-6 col-sm-4 col-md-${column} item mb-md`}
            key={i}>
            <SanityImage input={item.image} />
            <p className='py-xs whitespace-pre-line'>{item.title}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default MosaicImageTexte;
